import React from "react"
import SearchTableListOrder from "./SearchTableListOrder"
import DataTable from "./DataTable"
import ActiveTabsPage from "./ActiveTabsPage"

const ListDataTable = () => {
  return (
    <div className="mt-4">
      <ActiveTabsPage />
      <SearchTableListOrder />
      <DataTable />
    </div>
  )
}

export default ListDataTable

import React, { useContext } from "react"
import {
  Checkbox,
  Col,
  Form,
  Row,
  Typography,
  Input,
  Button,
  Divider,
  DatePicker,
} from "antd"
import "../style.scss"
import { MySalesManagementContext } from "../index.jsx"

export default function SearchTableListOrder() {
  const { setSearchParams, loading } = useContext(MySalesManagementContext)
  const onFinish = (values) => {
    console.log(values)
    setSearchParams(values)
  }
  return (
    <div className="customForm">
      <div style={{ color: "#595959" }}>絞り込み条件</div>
      <Form
        colon={false}
        onFinish={onFinish}
        style={{ backgroundColor: "#FAFAFA" }}
        requiredMark={false}
      >
        <Row
          className="p-4"
          gutter={[
            { xxl: 5, xl: 10, lg: 10, md: 10, sm: 10, xs: 10 },
            { xxl: 5, xl: 10, lg: 10, md: 10, sm: 10, xs: 10 },
          ]}
        >
          <Col xxl={5} xl={9} lg={8} md={8} sm={12} xs={24}>
            <div className="flex items-center w-full h-full">
              <Form.Item name="startDate">
                <DatePicker
                  format={"YYYY年MM月DD日"}
                  style={{ width: "100%" }}
                  placeholder="2022年1月"
                />
              </Form.Item>
              <span className="mx-[5px] block w-1/5">から</span>
            </div>
          </Col>
          <Col xxl={5} xl={9} lg={8} md={8} sm={12} xs={24}>
            <div className="flex items-center w-full h-full">
              <Form.Item name="endDate">
                <DatePicker
                  format={"YYYY年MM月DD日"}
                  style={{ width: "100%" }}
                  placeholder="2022年2月"
                />
              </Form.Item>
              <span className="mx-[5px] block w-1/5">まで</span>
            </div>
          </Col>
          <Col xxl={8} xl={21} lg={21} md={21} sm={21} xs={18}>
            <div className="flex items-center w-full h-full">
              <Form.Item name="keyword">
                <Input
                  style={{ width: "100%" }}
                  placeholder="キーワードで探す"
                  allowClear
                />
              </Form.Item>
            </div>
          </Col>
          <Col xxl={2} xl={3} lg={3} md={3} sm={3} xs={6}>
            <div className="flex items-center w-full h-full">
              <Button
                style={{
                  width: "100%",
                  marginLeft: 20,
                  backgroundColor: "#EC4D43",
                  border: 0,
                  letterSpacing: "0px !important",
                }}
                type="primary"
                loading={loading}
                htmlType="submit"
              >
                <span>検</span>
                <span>索</span>
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
